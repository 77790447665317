import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["status"]
 
  connect() {
    // this.element.textContent = "Hello World! from stimulus controller hello_controller.js"
    console.log("Hello, Stimulus! connect() successful!", this.element)
  }

  check() {
    this.statusTarget.classList.remove('bg-danger')
    this.statusTarget.classList.add('bg-success')
    this.statusTarget.textContent = "ok"
  }
}
