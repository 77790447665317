// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// this syntax is for asset pipleline, NOT for webpack starting with rails 6.x
//= require jquery3
//= require popper
//= require bootstrap-sprockets

import 'bootstrap';

import Rails from "@rails/ujs"

// OLD: replaced by turbo-rails
// import Turbolinks from "turbolinks"

// The Turbo instance is automatically assigned to window.Turbo upon import:
import "@hotwired/turbo-rails"


// DISABLE Turbo inline content replacement
// import { Turbo } from "@hotwired/turbo-rails"
// Turbo.session.drive = false

// import app/javascript/controllers/*
import "controllers"

import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "@fortawesome/fontawesome-free/js/all"
// import "@fortawesome/fontawesome-free/css/all"

import Chart from 'chart.js/auto';
// register Chart class to enable call new Chart() 
global.Chart = Chart;

Rails.start()

// OLD: replaced by turbo-rails
// Turbolinks.start()

ActiveStorage.start()

