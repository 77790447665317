import { Controller } from "@hotwired/stimulus"
import { Tooltip } from "bootstrap"

export default class extends Controller {
  static targets = ["status"]

  connect() {
    console.log("My Bootstrap Controller connected", this.element)
  }

  check() {
    this.statusTarget.classList.remove('bg-danger')
    this.statusTarget.classList.add('bg-success')
    this.statusTarget.textContent = "try to get Version"

    // var modal = new Modal(this.element)
    this.statusTarget.textContent = Tooltip.VERSION

  }
}
